<template>
    <div>
        <vx-card class="mt-4" title="Todo sobre el sistema.">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="mb-5">
                A continuación podrás encontrar los datos más relevantes sobre el {{this.mainTitle}} de paneles solares.
                Los costos podrán variar conforme a la cotización final del proyecto. 
              </p>
            </div>
            <div class="vx-col w-full">
                <vs-button color="primary" @click="isPopupFormActive = true">Estoy interesad@</vs-button>
            </div>
          </div>
        </vx-card>

        <vx-card v-if="isMounted" class="mt-4">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vx-card title="El sistema.">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <div class="choose-fluid">
                                <div class="modal-dialog show-more-modal fund" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="vx-row" :key="indextr" v-for="(technology, indextr) in project.technologies">
                                                <div class="detail-section">
                                                    <div class="vx-row">
                                                        <div class="vx-col sm:w-1/2 lg:w-1/5 w-full">
                                                            Marca de {{technology.product}}
                                                            <div class="data-pill">{{technology.brand}}</div>
                                                        </div>
                                                        <div class="vx-col sm:w-1/2 lg:w-1/5 w-full">
                                                            Cantidad
                                                            <div class="data-pill">{{technology.quantity}}</div>
                                                        </div>
                                                        <div class="vx-col sm:w-1/2 lg:w-1/5 w-full">
                                                            Potencia
                                                            <div class="data-pill">{{technology.capacity}} {{technology.capacity_unit}}</div>
                                                        </div>
                                                        <div v-if="technology.product==='Paneles'" class="vx-col sm:w-1/2 lg:w-1/5 w-full">
                                                            Tiempo de vida
                                                            <div class="data-pill">{{technology.lifespan}} años</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </vx-card>
                    <vx-card class="mt-4" title="Costos y ahorros.">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <div class="choose-fluid">
                                <div class="modal-dialog show-more-modal fund" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="vx-row">
                                                <div class="detail-section">
                                                    <div class="vx-row">
                                                        <div class="vx-col sm:w-1/2 lg:w-1/4 w-full">
                                                            Ahorro Estimado Mensual
                                                            <div class="data-pill pill-primary">${{format_price(project.saving_per_year/12)}}MXN</div>
                                                        </div>
                                                        <div class="vx-col sm:w-1/2 lg:w-1/4 w-full">
                                                            Precio original del sistema
                                                            <div class="data-pill">${{format_price(project.total_cost)}}</div>
                                                        </div>
                                                        <div class="vx-col sm:w-1/2 lg:w-1/4 w-full">
                                                            Descuento del sistema
                                                            <div class="data-pill">{{format_price((project.discount)*100)}}%</div>
                                                        </div>
                                                        <div class="vx-col sm:w-1/2 lg:w-1/4 w-full">
                                                            Nuevo precio especial
                                                            <div class="data-pill pill-bg-primary">${{format_price((project.total_cost-(project.total_cost*project.discount)))}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </vx-card>
                </div>
                <div class="vx-col w-full">
                    <vs-button color="primary" class="mt-5" @click="isPopupFormActive = true">Estoy interesad@</vs-button>
                </div>
            </div>
        </vx-card>

        <!-- Popup para formulario de lead -->
        <vs-popup v-if="isMounted" :active.sync="isPopupFormActive" :title="mainTitle" class="extended-popup header-primary">
            <vx-card title="Confirma los datos de contacto.">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-5">
                        A continuación se muestran los datos de contacto registrados en tu cuenta.
                        Modifica los que consideres necesarios para que la información que recibas
                        concuerde con tus necesidades. 
                    </p>
                </div>
                <div class="vx-col w-full">
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 lg:w-1/3 w-full">
                        <vs-input label="Nombre *" class="w-full mt-4" v-model="leadData.name" />
                    </div>
                    <div class="vx-col sm:w-1/2 lg:w-1/3 w-full">
                        <vs-input label="Correo electrónico *" class="w-full mt-4" v-model="leadData.email" />
                    </div>
                    <div class="vx-col sm:w-1/2 lg:w-1/3 w-full">
                        <vs-input label="Teléfono *" class="w-full mt-4" v-model="leadData.phone" />
                    </div>
                    <div class="vx-col sm:w-1/2 lg:w-1/3 w-full">
                      <vs-select
                      placeholder="Seleccione"
                      @input="onStateChange"
                      class="mt-5 w-full"
                      label="Estado *"
                      v-model="leadData.state_id"
                      >
                        <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in states" />
                      </vs-select>
                    </div>
                    <div class="vx-col sm:w-1/2 lg:w-1/3 w-full">
                        <vs-select
                        placeholder="Seleccione"
                        class="mt-5 w-full"
                        label="Ciudad *"
                        v-model.lazy="leadData.city_id"
                        >
                          <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in cities" />
                        </vs-select>
                    </div>
                  </div>
                </div>
                <div class="vx-col flex w-full mt-6 mb-5">
                    <vs-button color="primary" @click="sendForm">Confirmar</vs-button>
                    <vs-button class="ml-2" color="primary" type="border" @click="isPopupFormActive = false">Cancelar</vs-button>
                </div>
            </div>
            </vx-card>
        </vs-popup>     
        
        <!-- Popup para registro de lead exitoso -->
        <vs-popup v-if="isMounted" :active.sync="isPopupSuccessActive" title="¡Listo!" class="header-primary">
            <vx-card title="Gracias por tu interés.">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-5">
                      En breve uno de nuestros agentes de venta se pondrá en contacto
                      contigo para darte mayor información sobre el sistema.
                    </p>
                </div>
            </div>
            </vx-card>
        </vs-popup>        
    </div>
</template>

<script>
import formatHelper from '@components/mixins/formatHelper';
export default {
  props: ['id','project','states'],
  mixins: [formatHelper],
  data(){
    return {
      isMounted:false,
      isFirstTime:true,
      isPopupFormActive:false,
      isPopupSuccessActive:false,
      cities:[],
      leadData:{
        name:"",
        email:"",
        phone:"",
        state_id:"",
        city_id:"",
        project_id:""
      }
    }
  },
  computed: {
    mainTitle(){
        if (this.project.total_capacity) {
            return "Sistema de "+this.project.total_capacity+" en "+this.project.location;
        }else{
            return "Sistema en "+this.project.location;
        }
    }
  },
  async mounted () {
    this.isMounted = false;
    this.leadData.name=this.user.full_name;
    this.leadData.email=this.user.email;
    this.leadData.phone=this.user.phone;
    this.leadData.state_id=this.user.state_id;
    this.leadData.project_id=this.id;
    await this.onStateChange();
    this.isMounted = true;
  },
  methods:{
    async onStateChange(){
      if(!this.leadData.state_id || this.leadData.state_id === 0 ){
        return;
      }
      this.leadData.city_id="";
      this.showLoading(true);
      try {
        let res = await this.publicApiGet('/api/register/cities/' + this.leadData.state_id);
        this.cities = res.data;
        if (this.isFirstTime) {
          this.leadData.city_id=this.user.city_id;
          this.isFirstTime=false;
        }
        this.showLoading(false);
      }
      catch (e) {
        this.showLoading(false);
        this.warn(e);
      }
    },
    async sendForm(){
      if(this.validateForm()){
        await this.doSendForm();
      }
    },
    async doSendForm(){
      this.showLoading(true);
      try {
        const payload = { lead: this.leadData }
        await this.publicApiPost(`/api/v2/leads/registerResaleFromApp`, payload);
        this.showLoading(false);
        this.isPopupFormActive = false;
        this.isPopupSuccessActive = true;
      }
      catch (e) {
        this.showLoading(false);
        this.errorNotif("Aviso", 'Ha ocurrido un error, intenta más tarde.', 4000);
      }
    },
    validateForm(){
      let valid = (!this.isEmpty(this.leadData.name) && !this.isEmpty(this.leadData.email) && !this.isEmpty(this.leadData.phone) && this.leadData.state_id!==0 && this.leadData.city_id!==0 && this.leadData.project_id!==0)
      if(!valid){
        this.errorNotif("Aviso", 'Por favor completa todos los campos.', 4000);
        return false
      }
      let emailPattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailPattern.test(this.leadData.email)){
        this.errorNotif("Aviso", "Por favor ingresa un email válido 'ejemplo@mail.com'.", 4000);
        return false;
      }
      return true;
    },
    isEmpty(str){
      return (str === null || str === undefined || !str.trim() || !str.trim().length);
    },
  }
}
</script>
<style lang="scss">
.modal-banner{
  background-color: #28DE18;
  height:250px;
}

.show-more-modal.fund .modal-banner{
  background-color:#3b3a3e;
}

.show-more-modal, .know-more-modal{
  width: 100%;
}
.show-more-modal .modal-header{
  padding: 0;
}
.show-more-modal .modal-header img.close-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  width: 30px;
}
.show-more-modal .modal-banner img{
  width: 100%;
  height: auto;
  max-height: 270px;
  overflow: auto;
}

.show-more-modal .modal-banner .tittle-section{
  padding: 15px 15px;
  margin-top: 22px;
}

.show-more-modal .modal-banner h1,
.show-more-modal .modal-banner p{
  color:white;
  font-family: 'gilroybold';
}

.show-more-modal .modal-banner p{
  margin: 0;
}

.show-more-modal .modal-banner h1 > span,
.show-more-modal .modal-banner p > span{
  font-size: 37px;
  color:#3B3A3E;
  font-family: 'gilroybold';
}

.show-more-modal.fund .modal-banner h1 > span,
.show-more-modal.fund .modal-banner p > span{
  color:#3B3A3E;
}

.show-more-modal .modal-banner h1,
.show-more-modal .modal-banner h1 > span{
  font-size: 37px;
}
.show-more-modal .project-progress-bar{
  width: 100%;
  background-color: #ddd;
  margin-bottom: 5px;
  z-index: 1
}

.show-more-modal .project-progress-bar-advance{
  width: 15%;
  height: 20px;
  background-color: #33b2c1;
  z-index: 1
}

.show-more-modal.fund .project-progress-bar-advance{
  background-color: #5FC8D6;
}

.show-more-modal .modal-body p{
  margin: 5px;
  font-size: 13px;
}

.show-more-modal .detail-section{
  color: #3b3a3e;
  font-family: 'gilroybold';
  font-size: 15px;
  display: table;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}

.show-more-modal .data-pill{
  border: solid 2px #3b3a3e;;
  width: 100%;
  padding: 2px;
  border-radius: 18px;
  text-align: center;
  font-family: 'gilroybold';
  font-size: 16px;
  margin-bottom: 10px;
}

.pill-primary{
  border: solid 2px #28DE18 !important;
  color: #28DE18;
}

.pill-bg-primary{
  border: solid 2px #28DE18 !important;
  background-color: #28DE18 !important;
}

.show-more-modal .data-pill sub{
  font-family: 'gilroybold' !important;
}

.show-more-modal .data-concept{
  font-family: 'gilroybold';
  display: table-cell;
  float: none;
  vertical-align: bottom;
}

.show-more-modal .data-row{
  margin-top: 10px;
  display: table-row;
}

a.glyphicon:hover{
  text-decoration: underline;
}

.modal-open.modal{
  background-color: rgba(35,31,32, 0.5) !important;
  display: block;
}

.modal-content{
  border-radius: 0 !important;
}

.modal-backdrop{
  display:none !important;
}

img.close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 5%;
}

.project-description {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.6;
}


/*Estilo para tooltip*/
.tooltip-info {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-info .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-info .tooltiptext a{
  text-decoration: underline;
}

.tooltip-info:hover .tooltiptext {
  visibility: visible;
}

.tooltip-info .tooltiptext.right {
  top: -5px;
  left: 105%;
}

.tooltip-info .tooltiptext.left {
  top: -5px;
  right: 105%;
}

.tooltip-info .tooltiptext.top {
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-info .tooltiptext.bottom {
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.underline.bold.black {
  text-decoration: underline;
  font-family: 'gilroybold';
  color: black !important;
}

.interest-button {
  background-color: #00AEC2; /* Green */
  border: solid 1px;
  border-radius: 20px;
  padding: 5px !important;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 100%;
}

.interest-button:hover {
  background-color: white;
  color: #00AEC2;
  border-color: #00AEC2;
}
</style>
